import { sdk as imageKitSDK } from '@wix/image-kit'

export default class Platform {
  constructor({ platformUtils, wixSdk, bi, devMode, verbose }) {
    // you need to note, that some of those params are not static during app's lifecycle
    // some are static nowm but may change in the future.
    // for non-static props see the `#user` implementation
    this.#settings = this.#getSettings({ wixSdk, bi, devMode, verbose })
    this.#user = this.#getUser(wixSdk.user)
    this.#location = this.#getLocation(wixSdk.location, bi)
    this.#utils = this.#getUtils(platformUtils)
  }

  get settings() {
    return this.#settings
  }

  get user() {
    return this.#user
  }

  get location() {
    return this.#location
  }

  get utils() {
    return this.#utils
  }

  #settings
  #user
  #location
  #utils

  #getSettings({
    wixSdk: {
      window: {
        viewMode,
        rendering: { env },
        browserLocale,
      },
      site: { regionalSettings = browserLocale },
    },
    bi: { metaSiteId, viewerSessionId, viewerName },
    devMode,
    verbose,
  }) {
    return {
      metaSiteId,
      session: viewerSessionId,
      locale: regionalSettings,
      mode: {
        name: env,
        dev: devMode,
        verbose,
        ssr: env === 'backend',
        csr: env !== 'backend',
      },
      env: {
        name: viewMode,
        live: viewMode === 'Site',
        preview: viewMode === 'Preview',
        livePreview: viewMode === 'Editor',
        editor: viewMode === 'Preview' || viewMode === 'Editor',
        renderer: viewerName,
      },
    }
  }

  #getUser(user) {
    return {
      get id() {
        return user.currentUser.id
      },
      get loggedIn() {
        return user.currentUser.loggedIn
      },
      onLogin: user.onLogin,
    }
  }

  #getLocation({ baseUrl, to }, { pageId, pageUrl }) {
    return { pageId, pageUrl, baseUrl, navigateTo: to }
  }

  #getUtils({ links, mediaItemUtils }) {
    return {
      links,
      media: {
        ...mediaItemUtils,
        getScaleToFillImageURL: imageKitSDK.getScaleToFillImageURL,
      },
    }
  }
}
